// Customer service overlays on checkout

#colorbox {
  .customer-service {
    &-section div {
      display: block;
    }
    p {
      margin: 0 0 20px;
    }
    &__subtitle {
      margin-bottom: 20px;
    }
    &__option {
      font-weight: bold;
      margin: 20px 0;
    }
    &__step,
    .please-note {
      font-weight: bold;
    }
    &-menu,
    &-quick-info {
      display: none;
    }
    &-header,
    &-section {
      width: 100%;
    }
    p.customer-service__note {
      margin-left: 40px;
    }
    .shipping-options,
    .sales-tax,
    .returns-policy,
    .maximum-purchase-policy {
      &__content {
        display: block;
        margin: 0 20px;
        padding-top: 20px;
      }
    }
    .shipping-option__table {
      width: 70%;
      tr {
        td {
          border: none;
          &:first-child {
            font-weight: bold;
          }
        }
      }
    }
    .delivery-handling__list {
      margin-bottom: 20px;
      li {
        margin-left: 40px;
      }
    }
  }
}
