@mixin txttrans() {
  &::-webkit-input-placeholder {
    text-transform: none;
  }
  &:-moz-placeholder {
    text-transform: none;
  }
  &:-ms-input-placeholder {
    text-transform: none;
  }
  &::-moz-placeholder {
    text-transform: none;
  }
}

#lpChat {
  input[type='text'] {
    @include txttrans();
  }
  input[type='email'] {
    @include txttrans();
  }
  textarea {
    @include txttrans();
  }
  select {
    text-transform: none;
  }
  option {
    text-transform: none;
  }
}

#footer_sticky {
  min-height: 65px;
}

#lpChat {
  .lp_radiobutton_wrapper {
    .lp_radio_button {
      label::before,
      label::after {
        display: none;
      }
    }
  }
}

.touch {
  #lpChat .lp_lpview_content select {
    -moz-appearance: menulist;
    -webkit-appearance: menulist;
    appearance: menulist;
  }
  #footer_sticky {
    min-height: 63px;
  }
}

body.page-product-110333 {
  .elc-ugc-v1 {
    display: none;
  }
}
