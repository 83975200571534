.account {
  .profile-info {
    &__item {
      input[type='radio'] {
        width: 6%;
      }
      input.field {
        text-transform: none;
      }
    }
    &__required {
      display: inline;
    }
  }
  .optional-info {
    .optional-info__item {
      float: none;
      padding: 0;
      a.error {
        border: 1px solid $color-red;
      }
    }
  }
  .account-page {
    &__content {
      #account-page__welcome {
        display: block;
      }
      .section-border {
        height: 2px;
        background: $color-black;
        margin-top: 20px;
      }
      .account-profile {
        &__sms_promo span {
          font-family: 'Brandon Text Bold';
        }
      }
      .account-payment {
        .section-header {
          padding: 0 30px;
        }
      }
    }
  }
  div.title-info_item {
    width: 50%;
  }
  .ui-widget-content {
    height: 12em;
    overflow-y: auto;
  }
  div.title_container {
    width: 100%;
  }
  .newsletter-info {
    .sms-info__item {
      clear: both;
      margin-bottom: 10px;
      label {
        padding-left: 10px;
      }
    }
  }
  .past-purchases-page {
    .samples {
      .product-info {
        &__actions {
          display: none;
          visibility: hidden;
        }
      }
    }
  }
  .sign-in-page {
    input[type='email'] {
      width: 100%;
    }
  }
  #container {
    #content {
      #migrated_user_conversion {
        input[type='radio'] {
          position: static;
          vertical-align: middle !important;
        }
      }
    }
  }
}

.new-account__item,
.request-form__item,
.sent-info__text,
.password-reset__item,
.return-user__item {
  margin-bottom: 0.8em;
}

.signin-page {
  max-width: 1024px;
  margin: 40px auto;
}

.account__panel-title {
  font-family: 'Brandon Text Bold';
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-indent: 0;
  margin: 4px 0 10px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid $color-black;
}

#address_form_container {
  padding: 0;
  div.address-form__title-container {
    width: 100%;
  }
  #qas_search_target {
    margin-top: 10px;
  }
}

.password-reset__item {
  .field {
    display: block;
  }
}

.favorites-page {
  .product-image {
    a img {
      display: block;
      height: 136px;
    }
  }
}

.address-book-page {
  .address-book__others {
    .address-book__header {
      text-indent: 0px;
    }
  }
}

.order-details-page {
  .order-details-page__content {
    .order-details-page__header {
      font-family: 'Brandon Text';
      font-size: 1.5em;
      letter-spacing: 1px;
      text-transform: none;
      margin: 0px 0px 40px 0px;
      padding: 0px;
      border-bottom: 0px;
    }
    .transaction-panel-details {
      .order-info {
        .trans_detail_item {
          float: none;
          margin-bottom: 0px;
          margin-right: 0px;
          width: auto;
        }
      }
      .order-details {
        div {
          float: none;
          width: auto;
        }
      }
    }
    .order-products {
      .cart-header {
        div {
          float: left;
        }
        .products {
          width: 44%;
        }
        .qty {
          width: 21%;
        }
        .price {
          width: 16%;
        }
        .total {
          width: 17%;
        }
      }
      .cart-item {
        .cart-item__qty {
          padding-top: 0px;
          width: 11%;
        }
        .cart-item__price {
          width: 19%;
          text-align: center;
        }
        @media screen and (max-width: 768px) {
          .cart-item__price {
            width: 21%;
          }
        }
        .cart-item__total {
          text-align: right;
          width: 17%;
        }
      }
    }
    .order-totals {
      float: right;
      width: 30%;
      margin-right: 5.5%;
      table {
        table-layout: auto;
        td {
          float: right;
          padding: 0.725em 0;
        }
      }
    }
  }
}

.create-wishlist__link--submit {
  padding: 9px;
}

.create-wishlist__name {
  margin-top: 10px;
}

.past-purchases-page {
  .product-info__desc {
    float: left;
    width: 75%;
  }
}

@include breakpoint($medium-up) {
  #address {
    .form-item {
      display: inline-block;
      width: 48%;
      margin: 10px 0;
      &.address-form {
        &__title-container {
          span {
            display: inline-block;
          }
        }
      }
      input[type='text'],
      input[type='password'],
      input[type='tel'],
      input[type='email'] {
        width: 95%;
      }
      select.state_select {
        width: 95%;
        height: 32px;
      }
    }
    .default_ship_container {
      margin: 10px 0;
      span {
        display: inline-block;
      }
    }
    .qas_container {
      input[type='text'],
      input[type='password'],
      input[type='tel'],
      input[type='email'] {
        width: 94.5%;
      }
    }
  }
}

#replenish {
  .replenishment-detail-page {
    overflow: hidden;
    max-width: 1024px;
    margin: 40px auto;
    &__content {
      .shipping-frequency {
        .frequency-table {
          &__cell {
            padding: 20px 1px 40px 0px;
          }
        }
      }
    }
  }
}

.pending-pro_print {
  @include breakpoint($medium-up) {
    margin-#{$ldirection}: 20px;
  }
}

.pro_level_criteria,
.pending-pro_level {
  ul {
    list-style-type: disc;
    padding-left: 20px;
    padding-bottom: 20px;
  }
}

.registration-pro-page {
  label {
    &.error {
      color: $color-red;
    }
  }
}
