/* Gnav */
.site-utils__dropdown--account {
  @include breakpoint($xlarge-screen) {
    height: 450px;
    overflow-y: scroll;
  }
}
/* Account Signin Page */
.account__section {
  .sign-in-page__container {
    .social-login {
      &__email-opt-in,
      &__terms {
        margin-#{$ldirection}: 20px;
      }
    }
  }
}
/* Profile Page */
.profile-page__content {
  .social-info {
    &__header {
      margin: 0 10px;
      @include breakpoint($medium-up) {
        margin: 0;
      }
    }
    &__content {
      p {
        margin-bottom: 15px;
      }
    }
    &__connect {
      @include breakpoint($medium-up) {
        display: flex;
      }
      span {
        @include breakpoint($medium-up) {
          margin-#{$ldirection}: 10px;
        }
      }
    }
  }
}
/* Checkout Index Page */
.checkout__index-content {
  .social-login {
    &__email-opt-in,
    &__terms {
      margin-#{$ldirection}: 10px;
    }
  }
}
/* Order Confirmation Page */
.confirmation-panel__container {
  .social-login {
    padding: 0;
    margin-top: 60px;
    border-top: 1px solid $color-light-gray;
    @include breakpoint($medium-up) {
      margin-top: 40px;
      display: inline-block;
      width: 60%;
    }
    &__container {
      padding-top: 0;
      @include breakpoint($medium-up) {
        padding-top: 0;
      }
    }
    padding-#{$ldirection}: 0;
    &__info,
    &__email-opt-in {
      @include breakpoint($medium-up) {
        margin-#{$ldirection}: 20px;
      }
    }
    .fb-login-button {
      @include breakpoint($medium-up) {
        margin-#{$ldirection}: 20px;
      }
    }
  }
}
/* Write a Review */
#power_review_container {
  .social-login {
    input[type='checkbox'] {
      #{$ldirection}: 50%;
      margin-top: 5px;
    }
  }
  #pwr_signin.sign-in-page {
    .social-login__email-opt-in {
      float: none;
    }
  }
}
