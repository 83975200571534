@mixin mobile-sticky-btn {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1002;
  width: 100%;
  background: $color-white;
  padding: 20px;
}

.checkout {
  &__index-content {
    .payment-panel {
      .checkout {
        &__panel-title {
          font-family: $brandon-text-bold;
          font-weight: normal;
          font-size: 14px;
          line-height: 50px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          text-indent: 20px;
          margin: 0;
          padding-bottom: 0;
          background: $color-gray-background;
          border-bottom: 1px solid $color-gray-border;
        }
      }
      &__confirm_text {
        padding: 15px;
      }
      .payment-edit {
        display: none;
      }
      &.active {
        .continue-button-wrapper {
          @include breakpoint($medium-up) {
            position: inherit;
            padding: 10% 0 0 0;
          }
          @include mobile-sticky-btn;
        }
        .payment-edit-content {
          margin-left: 0;
          @include breakpoint($medium-up) {
            margin-left: 20px;
          }
          .gift-cards {
            @include breakpoint($medium-up) {
              .giftcard_balance {
                .continue-btn {
                  clear: both;
                  padding-top: 3%;
                }
                .gift-card-number {
                  width: 49%;
                  float: left;
                  text-transform: uppercase;
                  .invalid_marker {
                    display: none;
                  }
                }
                .gift-card-pin {
                  width: 49%;
                  float: right;
                  text-transform: uppercase;
                  .invalid_marker {
                    display: none;
                  }
                }
                .gift-card-entry-container {
                  padding-top: 5%;
                }
              }
              input[type='text'],
              input[type='password'] {
                float: right;
                margin-bottom: 2%;
              }
              input[type='password'] + label,
              input[type='text'] + label {
                display: block;
                height: 3em;
                line-height: 4em;
                margin-top: calc(-3em - 14px);
                cursor: text;
                float: left;
              }
            }
          }
          .payment_container {
            .payment-option {
              label {
                margin-right: 0;
              }
              &.form-item.radio:first-child {
                width: 100%;
                letter-spacing: 0;
              }
              &.form-item.radio {
                width: 100%;
                letter-spacing: 0;
                margin: 10px 0;
              }
              @include breakpoint($medium-up) {
                width: 17%;
                float: left;
                &.radio:first-child {
                  width: 75%;
                  float: left;
                  padding-right: 25%;
                }
              }
              width: 100%;
            }
          }
          .adyen-payment {
            padding-top: 20px;
            input {
              &.chckt-pm__radio-button {
                left: 0;
                position: relative;
                height: auto;
              }
              &.chckt-checkbox {
                left: 0;
                margin: 4px 10px 4px 0;
                position: relative;
                height: auto;
              }
            }
            .chckt-button {
              border-radius: 0;
              font-weight: 400;
              height: 45px;
              padding: 0;
              &:not(.chckt-button--disabled) {
                background-color: $color-black;
                &:hover {
                  background-color: $color-black !important;
                }
              }
            }
            .chckt-pm {
              &__pending-message {
                background-color: $color-black;
              }
              &__details {
                padding: 0 50px 20px 50px;
                .chckt-form-label {
                  margin-right: 0;
                  &--exp-date {
                    width: 55%;
                  }
                  &--cvc {
                    width: 40%;
                  }
                }
                .chckt-input-field--cvc {
                  max-width: 100%;
                }
              }
            }
          }
        }
        &.collapsed {
          display: none;
        }
      }
    }
  }
}
