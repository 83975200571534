$color-pink-light: #fc5669;
/// Mixins, Placeholders and Variables
@mixin checkout-sidebar-panel-border {
  margin-top: 6px;
  border: 5px solid $color-gray-light-alt;
  padding: 15px 15px 20px 15px;
  background: $color-white;
  &__title {
    margin-top: 34px;
  }
}


// Fixing the radio buttons being vertically misaligned
label:before {
  margin-top: -3px;
}

.checkout {
  .messages,
  .single-message {
    color: $color-red !important;
  }
  &#index {
    position: initial;
    h4.checkout-header {
      &__title {
        display: none;
      }
    }
    section {
      &.panel {
        position: initial;
        &.payment-panel,
        &.registration-panel,
        &.shipping-panel {
          position: relative;
        }
      }
    }
    .column {
      &.right {
        display: none;
      }
      &.left {
        width: 100%;
        .shipping-panel,
        .payment-panel {
          display: none;
        }
      }
    }
  }
  input[type='text'],
  input[type='password'],
  input[type='tel'],
  input[type='number'] {
    margin-bottom: 0px;
  }
  .right {
    #shopping-bag-panel {
      header {
        margin-bottom: -10%;
        .checkout {
          &__panel-title {
            padding-bottom: 10px;
            border: none;
          }
        }
      }
      .item-count {
        float: right;
        font-size: 14px;
        font-family: $brandon-text-bold;
        text-transform: uppercase;
        letter-spacing: 1.8px;
        font-weight: bold;
      }
    }
    #order-summary-panel {
      .shipping {
        .selectBox-dropdown {
          float: left;
          width: 100%;
          margin-top: 0;
        }
      }
    }
    #guarantee-panel {
      display: none;
    }
    #viewcart-panel {
      .product-list {
        min-height: 0px;
        .cart-item {
          .product {
            &__desc {
              width: 50%;
              .color {
                .swatch {
                  margin-top: -14%;
                }
                .shade {
                  width: 100%;
                }
              }
            }
          }
          .error {
            color: $color-red;
          }
        }
      }
    }
    &.display-footer {
      display: none;
    }
  }
  .loading {
    * {
      @include opacity(0.8);
    }
  }
  &__sidebar {
    // tabbing system (pulled over from checkout.css, on BrZ UK1)
    .tab-switcher {
      @include checkout-sidebar-panel-border;
      padding: 0px;
    }
    .tab-bar {
      @include clearfix;
      padding: 0px;
      li {
        @include clearfix;
        display: block;
        float: left;
        background-color: $color-gray-light-alt;
        width: 33%;
        text-align: center;
      }
    }
    .tab {
      display: block;
      width: 100%;
      text-decoration: none;
      color: $color-black;
      @include breakpoint($medium-up) {
        @include h22;
        padding: 10px 5px;
      }
      @include breakpoint($large-up) {
        @include h12;
        padding: 10px;
      }
      &.active {
        background-color: $color-white;
      }
    }
    .tab-content {
      padding: 15px;
    }
    .tab-pane {
      opacity: 0;
      height: 0px;
      overflow: hidden;
      -webkit-transition: opacity 1s ease;
      -moz-transition: opacity 1s ease;
      -o-transition: opacity 1s ease;
      -ms-transition: opacity 1s ease;
      transition: opacity 1s ease;
      &.active {
        display: block;
        opacity: 1;
        height: auto;
        footer {
          display: block;
          text-align: right;
          padding-top: 3%;
        }
      }
      footer {
        display: none;
      }
    }
    // end tabbing system

    // stuff for the checkout sidebar samples panel, the product rows
    .product {
      margin-top: 20px;
      padding-top: 16px;
      border-top: 1px solid $color-gray;
      @include breakpoint($small-down) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .product-img {
      max-width: 80px; // to match the small image size, which we're using for product images (regular and samples)
      margin: 0px 10px 0px 0px;
      float: left;
    }
    .details {
      margin-top: 0px;
      width: 69%;
      display: inline-block;
    }

    // hide the samples panel's add and no thanks buttons when in the sidebar (not the popup)
    .add-button,
    .no-thanks {
      display: none;
    }
    .remaining,
    .remaining p {
      margin-bottom: 0px;
      .available.hidden {
        margin-bottom: -19%;
      }
    }
    .offer-code-panel {
      margin-top: 6px;
      .content {
        border-bottom: 1px solid $color-gray;
        margin-top: 0px;
        p {
          margin: 0px 0px 15px 0px;
        }
        input[type='text'] {
          width: 100%;
        }
        input.btn[type='submit'] {
          width: 35%;
          height: 45px;
        }
        .offer-code {
          &__byline {
            display: block;
            padding: 4% 0 3% 0;
            .js__edit-offer {
              cursor: pointer;
            }
          }
        }
        #offer_code {
          margin-bottom: 15px;
          .btn[type='submit'] {
            height: 31px;
            vertical-align: top;
            margin-top: 2%;
          }
          .offer-code {
            &__input {
              input[type='text'] {
                text-transform: none;
              }
              ::-webkit-input-placeholder {
                text-transform: initial;
              }
              :-moz-placeholder {
                text-transform: initial;
              }
              ::-moz-placeholder {
                text-transform: initial;
              }
              :-ms-input-placeholder {
                text-transform: initial;
              }
            }
          }
        }
      }
    }
    section {
      &#viewcart-panel {
        .replenishment__description {
          text-transform: none;
        }
        .cart-item__price {
          display: none !important;
        }
      }
      &#shopping-bag-panel {
        header {
          position: absolute;
        }
      }
      &.display-footer {
        display: none;
      }
    }
  }
  .panel label {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
  }
  &__index-content {
    .custom-outline {
      outline: none !important;
    }
    .right {
      .order-summary-panel {
        .content {
          .subtotal,
          .giftwrap {
            padding: 0px;
            margin: 0px;
          }
          .shipping {
            margin: 0;
            &.value,
            &.label {
              padding: 0px;
            }
          }
        }
      }
    }
    .checkout__content {
      margin-top: 2.3%;
      .adpl {
        label {
          display: block !important;
          visibility: visible;
        }
        input[type='tel'] + label,
        input[type='password'] + label,
        input[type='text'] + label,
        select + label {
          display: block;
          height: 3em;
          line-height: 3em;
          margin-top: calc(-3em - 1px);
          cursor: text;
          float: left;
        }
        input[type='email'] + label {
          display: block;
          height: 3em;
          line-height: 0em;
          margin-top: calc(-3em - 7px);
          cursor: text;
          float: left;
        }
        input[type='radio']:required:invalid:not(:checked).checkedEmpty + label {
          color: $color-pink;
        }
      }
      .phone_requirements {
        margin: 15px 0px;
      }
    }
  }
  .continue-buttons {
    .disabled {
      pointer-events: none;
      cursor: default;
    }
  }
}

.order-summary {
  &-panel {
    .total {
      font-family: $brandon-text-bold;
    }
    .value {
      padding-right: 20px;
      line-height: 19px;
    }
  }
  &__total-label,
  &__total-value {
    border-top: none;
  }
  &__content {
    .order-summary {
      &__total-label {
        border-top: 1px solid $color-gray-border;
        border-bottom: none;
        text-align: left;
      }
      &__total-value {
        border-top: 1px solid $color-gray-border;
        border-bottom: none;
      }
      &__subtotal-label,
      &__shipping-label {
        text-transform: uppercase;
        width: 80%;
        text-align: left;
        margin: 10px 0px;
        line-height: 33px;
      }
      &__subtotal-value,
      &__shipping-value {
        width: 20%;
        white-space: nowrap;
        text-align: right;
        float: left;
        margin: 10px 0px;
        line-height: 33px;
      }
    }
    .tax {
      line-height: 40px;
    }
    .giftwrap {
      &.label {
        text-transform: uppercase;
        width: 80%;
        text-align: left;
        margin: 10px 0px;
        line-height: 33px;
      }
      &.value {
        width: 20%;
        white-space: nowrap;
        text-align: right;
        float: left;
        margin: 10px 0px;
        line-height: 33px;
      }
    }
  }
}

//Need Help Panel
.need-help-panel {
  background-color: $color-gray-background;
  padding: 5%;
  .content {
    .link {
      border: none;
      text-transform: none;
      font-family: $brandon-text;
      pointer-events: none;
    }
  }
}

//Links Panel
.links-panel {
  .content {
    @media screen and (min-width: 768px) {
      .links_list {
        li {
          border: none;
          .overlay-link {
            border-width: 0px 0px 2px 0px !important;
          }
        }
      }
    }
    .link {
      @include breakpoint($medium-up) {
        border-bottom: none;
        margin-bottom: 5px;
        a {
          @include link($color-pink, $color-black);
        }
      }
    }
  }
  &__title {
    .checkout__sidebar & {
      margin-top: 0px;
    }
  }
}

@media (min-width: 1025px) {
  .checkout__sidebar {
    .tab {
      padding: 10px 6px;
      font-size: 9px;
    }
  }
}

.order-details-page {
  &__content {
    .order-totals {
      .checkout {
        &__panel-title {
          border-bottom: none;
        }
      }
      .total {
        border-top: 1px solid $color-black;
      }
      .discount {
        &.label {
          text-align: left;
          padding-bottom: 10px;
        }
      }
    }
  }
}

.samples-page {
  .wp-product-list {
    .grid-2 {
      .details {
        text-align: center;
      }
      .view-larger-img {
        margin-left: 18%;
        img {
          width: 65%;
        }
      }
    }
  }
}
