.checkout {
  section.checkout {
    &__index-content {
      .checkout__content {
        .new-address {
          .form-item,
          .form_element {
            @include breakpoint($small-down) {
              width: 100%;
            }
            width: 45%;
            display: inline-block;
            &.title {
              display: block;
              width: 100%;
              margin-bottom: 3%;
              label {
                outline: none;
              }
              span.label-content {
                display: block;
              }
            }
            &.first-name,
            &.phone-2 {
              width: 50%;
              float: left;
              clear: none;
            }
            &.last-name,
            &.phone-1 {
              float: none;
              width: 50%;
              padding-right: 0;
              padding-left: 2px;
            }
            &.postal_code_container {
              display: block;
              width: 100% !important;
              input {
                width: 45%;
                @include breakpoint($small-down) {
                  width: 50%;
                }
              }
              span.lookup_submit_note {
                position: relative;
                top: -38px;
                display: block;
                left: 0px;
                @include breakpoint($small-down) {
                  width: 100%;
                  margin-left: 0;
                  top: 0px;
                }
              }
              span.fieldset-note {
                left: 275px;
                position: relative;
                top: -55px;
                margin-left: 10px;
                @include breakpoint($small-down) {
                  margin-left: 5px;
                  left: 0px;
                  top: 0px;
                }
                .address_lookup_submit {
                  padding: 15px;
                  position: absolute;
                  width: 150px;
                  font-size: 12px;
                  top: -10px;
                  @include breakpoint($small-down) {
                    display: inline;
                    vertical-align: middle;
                    margin: 0;
                  }
                }
              }
              .lookup_results_container {
                width: 90%;
              }
            }
            &.city_container {
              position: relative;
            }
            &.state_container {
              margin-bottom: 20px;
            }
          }
          .edit-shipping-radio,
          .edit-billing-radio {
            clear: left;
            padding-top: 20px;
            padding-bottom: 20px;
            label {
              outline: none;
            }
          }
          .shipping-address-display {
            display: block;
          }
          .qas_container {
            width: 100%;
            #SHIPFORM__qas_search {
              padding: 20px 15px;
            }
          }
          .shipping-address {
            .default-shipping {
              width: 50%;
              .label-content {
                display: initial;
              }
              label {
                margin: 0px;
              }
            }
          }
        }
        .gift-options {
          input[type='text'],
          textarea {
            &[data-error] {
              border-color: $color-red;
            }
          }
        }
        input,
        select {
          border-color: $color-light-gray;
          height: 45px;
        }
        textarea {
          border-color: $color-light-gray;
          padding: 10px 10px;
        }
        input.error,
        select.error,
        a.selectBox.error,
        a.selectbox.error {
          border-color: $color-red;
        }
        #address_form_container {
          border: 0;
          padding: 0;
        }
        #checkout_shipping_panel {
          margin: 0;
          .shipping-edit-address-content {
            &__title {
              display: block;
            }
          }
        }
        #shipping-panel {
          .checkout {
            &__panel-title {
              font-family: $brandon-text-bold;
              font-weight: normal;
              font-size: 14px;
              line-height: 50px;
              letter-spacing: 0.2em;
              text-transform: uppercase;
              text-indent: 20px;
              margin: 0;
              padding-bottom: 0;
              background: $color-gray-background;
              border-bottom: 1px solid $color-gray-border;
            }
          }
          #billing-address-display {
            margin-left: 4%;
            width: 43%;
            padding: 45% 0 5% 0;
            #payment-address-h {
              h3 {
                border-bottom: 1px solid $color-black;
              }
            }
          }
          .edit-controls {
            .shipping-panel {
              &__title {
                float: left;
                width: 100%;
                #shipping-panel-edit {
                  float: left;
                }
              }
            }
            #shipping-panel-edit {
              float: right;
              clear: both;
            }
          }
          .shipping-address,
          .billing-address-form-content {
            width: 100%;
            .name-fields,
            .qas,
            .address1,
            .address2,
            .city,
            .state {
              margin-bottom: 20px;
              width: 100%;
            }
            .city-state-zip {
              margin-bottom: 20px;
              .city {
                width: 50%;
              }
              .state {
                width: 25%;
                clear: none;
                padding-left: 10px;
                .label-content {
                  display: none;
                }
              }
              .postal-code {
                width: 24%;
                float: right;
                clear: none;
                padding: 0 0 0 10px;
              }
            }
            input[name='qas_search'] {
              padding: 20px 15px;
            }
          }
          .gift-options {
            &__content {
              p {
                margin-bottom: 2%;
              }
              .card-message-to,
              .card-message-from {
                margin-bottom: 20px;
              }
              .gift-options {
                .form-item {
                  position: inherit;
                }
              }
            }
          }
          #gift-options {
            .gift-message {
              &-h {
                &__title {
                  display: block;
                  margin-top: 25px;
                }
              }
            }
          }
          &.adpl {
            input[type='text'] {
              &.js-label-mode + label {
                opacity: 1 !important;
              }
              &.js-placeholder-mode + label {
                opacity: 0 !important;
              }
            }
          }
          &.finished {
            header.edit-controls {
              h2 + a.edit {
                margin-top: -6%;
                z-index: 10;
                right: 0px;
                bottom: 15px;
                text-transform: uppercase;
                padding-left: -20%;
                letter-spacing: 0.15em;
                cursor: pointer;
                font-weight: 700;
                padding: 2px 20px;
              }
            }
          }
        }
        #guarantee-panel {
          display: none;
        }
      }
    }
  }
  .shipping-panel {
    &.adpl {
      .two-column-form {
        .invalid_marker {
          display: none;
        }
        .title {
          label {
            display: inline-block !important;
            &.error {
              color: $color-red;
            }
          }
        }
      }
    }
  }
}
