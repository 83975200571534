.account_payment {
  .account-breadcrumb-wrapper {
    margin-bottom: 20px;
    .back-label {
      padding: 10px;
    }
    @include breakpoint($medium-up) {
      display: none;
    }
  }
  .account-payment-info-page {
    margin: 7%;
    @include breakpoint($medium-up) {
      margin: 40px auto;
    }
    .account-utilities {
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
        float: left;
        margin-right: 32px;
      }
    }
    &__content {
      float: left;
      .account-payment-info-page_header {
        font-family: $header-font-family;
        font-weight: 400;
        font-size: 21px;
        letter-spacing: 0.24em;
        text-transform: uppercase;
      }
      .payment_book_container {
        padding-top: 5%;
        .payment-info {
          &__header {
            padding-top: 2%;
          }
          &__other {
            padding-bottom: 5%;
          }
        }
      }
    }
  }
  .add-payment-page {
    margin: 0;
    .add-payment-page__content {
      margin: 0;
      .payment-form {
        margin-left: 0;
        width: auto;
        .error_messages {
          color: $color-red;
          li {
            margin-left: 0;
          }
        }
        .adyen-fields {
          @include breakpoint($landscape-up) {
            width: 50%;
          }
        }
        &__submit {
          margin: 20px 0 0;
        }
      }
    }
  }
  .add-payment-page,
  .payment-form {
    &__header {
      padding-left: 0;
      @include breakpoint($landscape-up) {
        padding-left: 5px;
      }
    }
  }
}
