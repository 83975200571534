.site-header {
  .lpStaticButton {
    width: auto;
    margin: 0px;
    td {
      border: none;
      padding: 0px;
    }
    .lpPoweredBy,
    .lpEmtStarRating {
      display: none;
    }
    a img {
      position: relative;
      top: 4px;
    }
  }
}

.section-esearch {
  .search-results__header {
    .search-form {
      &__results-count {
        line-height: 35px;
        #{$rdirection}: 181px;
      }
      input[type=submit].search-form__submit {
        margin-#{$ldirection}: 0;
      }
    }
  }
  #search-wrapper {
    #summary,
    .results-summary,
    .results-header {
      display: none;
    }
  }
  #results-container {
    #col-1 {
      display: none;
    }
    #col-2 {
      float: none;
      width: 100%;
      #product-results {
        .results-header {
          .product-sort-container {
            display: none;
          }
        }
      }
      #featured-products {
        float: left;
        width: 100%;
      }
    }
  }
  #bestseller-results {
    .results-header {
      .product-sort-container {
        display: none;
      }
    }
  }
}

.summary-search-form,
.summary-chat-now {
  display: none;
}

.section-esearch {
  #search-wrapper {
    #results-container {
      #col-2 {
        #product-results {
          margin-left: 30px;
        }
      }
      #col-1 {
        #breadcrumbs,
        #dimensions {
          margin-bottom: 20px;
        }
        #dimensions,
        #breadcrumbs,
        #recent-searches {
          h3 {
            text-transform: uppercase;
            padding: 10px 0px 10px 10px;
            font-size: 1em;
            margin-bottom: 20px;
            border: 1px solid #bfbfbf;
          }
          dl {
            margin-left: 10px;
            dd {
              margin-left: 10px;
              padding-bottom: 3px;
              .close {
                margin-left: 5px;
              }
            }
            dt {
              cursor: pointer;
              margin-bottom: 5px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}

.tiny-waitlist-overlay {
  .email_label {
    margin-bottom: 5px;
  }
  .email_input {
    display: inline-block;
    input[type='text'] {
      width: 300px;
      margin-bottom: 10px;
    }
  }
  .waitlist-header {
    display: block;
    font-weight: bold;
    padding-bottom: 10px;
  }
  h2 {
    margin: 0 0 15px 0;
    text-align: left;
  }
  p.waitlist_message {
    margin-bottom: 10px;
  }
  .error_messages.error li {
    margin-left: 0px;
  }
  .field {
    border-top: 1px solid gray;
    padding-top: 10px;
    .align-r {
      display: block;
    }
  }
}

.waitlist_thankyou_message {
  font-family: $brandon-text-bold;
  margin: 90px 60px;
  line-height: 1.45em;
}

.waitlist-iframe-wrapper {
  height: 95%;
  width: 100%;
}

.site-footer__column {
  width: 28%;
}

.trustmark-logo {
  width: 100px;
}

.site-footer {
  .site-footer {
    &__column {
      padding: 0 15px;
    }
    &__column:last-child {
      width: 16%;
      padding: 0 0 0 20px;
    }
  }
}

.lp_buttons_area {
  button {
    &:hover {
      background: $color-black !important;
    }
  }
}
