@mixin mobile-sticky-btn {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1002;
  width: 100%;
  background: $color-white;
  padding: 20px;
}

@mixin mobile-checkout-title {
  font-family: $brandon-text-bold;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-indent: 20px;
  padding-bottom: 0;
  line-height: 50px;
  background: $color-gray-background;
  border-bottom: 1px solid $color-gray-border;
  border-top: 1px solid $color-gray-border;
}

@mixin edit-button-layout {
  z-index: 10;
  right: 0px;
  bottom: 15px;
  text-transform: uppercase;
  padding-left: -20%;
  letter-spacing: 0.15em;
  cursor: pointer;
  font-weight: 700;
  padding: 2px 20px;
  float: right;
}

.checkout {
  &__index-content {
    .signin-panel,
    .sign-in-panel {
      .new-account {
        padding: 0 20px 25px 20px;
        #checkout_signin_new_user {
          .form-item {
            input {
              width: 100%;
            }
          }
          #checkout_signin_new_user-submit {
            margin-top: 3%;
          }
        }
      }
      &.finished {
        .content {
          padding: 20px;
        }
      }
      &__title {
        font-family: $brandon-text-bold;
        font-weight: normal;
        font-size: 14px;
        line-height: 50px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        text-indent: 20px;
        margin: 0;
        padding-bottom: 0;
        background: $color-gray-background;
        border-bottom: 1px solid $color-gray-border;
        @include breakpoint($medium-up) {
          font-size: 18px;
          margin-top: 6%;
        }
      }
      #return-user {
        padding: 0 20px 25px 20px;
        #checkout_signin {
          .form-item {
            input {
              width: 100%;
            }
            &.password {
              margin-bottom: 20px;
              input[type='password'] {
                margin-bottom: 0;
              }
            }
          }
          .js-forgot-pw-note {
            padding-bottom: 10%;
          }
          #checkout_signin-submit {
            @include breakpoint($medium-up) {
              margin-left: 20px;
            }
          }
        }
      }
      &.adpl {
        input[type='text'] {
          &.js-label-mode + label {
            opacity: 1 !important;
          }
          &.js-placeholder-mode + label {
            opacity: 0 !important;
          }
        }
      }
      .messages {
        [id^='error_offer_criteria_'] {
          display: none;
        }
      }
    }
    .registration-panel {
      &.active {
        .checkout {
          &__panel-content {
            #checkout_registration_panel {
              .form-item {
                input[type='text'],
                input[type='password'] {
                  float: right;
                  margin-bottom: 10px;
                }
              }
              .checkout-password-label {
                display: none;
              }
              input[type='password'] + label,
              input[type='text'] + label {
                display: block;
                height: 3em;
                line-height: 3em;
                margin-top: calc(-3em - 12px);
                cursor: text;
                float: left;
              }
              .checkout-registration {
                &__email-address {
                  margin-bottom: 10px;
                  padding-top: 3%;
                }
              }
              .password-description {
                position: inherit;
              }
              .continue-button-wrapper {
                @include breakpoint($medium-up) {
                  margin-top: 10px;
                  position: inherit;
                }
                @include mobile-sticky-btn;
              }
            }
          }
          &__panel-title {
            @include mobile-checkout-title;
          }
        }
      }
      &.finished,
      &.collapsed,
      &.display {
        padding: 4% 0 4% 0;
        .checkout {
          &__panel-title {
            @include mobile-checkout-title;
            @include breakpoint($small-down) {
              font-size: 12px;
            }
            a {
              color: $color-black;
              text-decoration: none;
            }
          }
        }
        header {
          h2 + a.edit {
            @include edit-button-layout;
            margin-top: -7%;
            @include breakpoint($small-down) {
              font-size: 12px;
              margin-top: -14%;
              padding: 2px 10px;
            }
          }
        }
      }
      &.edit {
        .checkout {
          &__panel-title {
            @include mobile-checkout-title;
            a {
              color: $color-black;
              text-decoration: none;
            }
          }
        }
        header {
          h2 + a.edit {
            @include edit-button-layout;
            margin-top: -7.5%;
            @include breakpoint($small-down) {
              display: none;
            }
          }
        }
      }
    }
  }
}
