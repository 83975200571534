$color-dark-red: #c1100a;

.roulette {
  overflow: hidden;
  @include breakpoint($small-down) {
    .site-content {
      & > .block {
        position: relative;
      }
    }
  }
  #roulette-wrapper {
    text-align: center;
    box-sizing: border-box;
    margin: 0 auto;
    @include breakpoint($medium-down) {
      width: 100%;
      padding-bottom: 20px;
    }
    @include breakpoint($landscape-up) {
      width: 100%;
      margin: 60px auto 80px auto;
      height: 458px;
      min-width: 990px;
      max-width: 1024px;
      border: 4px solid $color-pink;
    }
    p {
      margin: 10px auto;
      &.spin-remaining,
      &.spins-remaining {
        color: $color-pink;
        font-size: 14px;
        text-transform: uppercase;
      }
      @include breakpoint($landscape-up) {
        width: 400px;
      }
    }
    .win-msg-product-copy {
      p {
        margin: 5px auto !important;
      }
      button {
        padding: 10px;
        width: 200px;
        margin: 10px 0;
        background: $color-pink;
        color: $color-white;
        letter-spacing: 1px;
        text-transform: uppercase;
        border: none;
        border-radius: 0;
        outline: none;
        cursor: pointer;
        position: relative;
        z-index: 3;
        height: auto;
        line-height: 14px;
      }
      h2 {
        color: $color-dark-red;
        text-transform: uppercase;
        font-weight: 800;
        line-height: 35px;
        font-family: $lato-black-bold;
        margin-bottom: 20px;
        &.prize {
          margin-top: 60px;
        }
      }
    }
    .product-image {
      height: 120px;
      width: auto;
      display: block;
      margin: 0 auto;
    }
    .play-button {
      &.disabled {
        background: $color-light-gray;
        cursor: default;
      }
    }
    & > div {
      box-sizing: border-box;
      position: relative;
      @include breakpoint($medium-down) {
        width: 100%;
        display: block;
        position: relative;
      }
      @include breakpoint($landscape-up) {
        width: 50%;
        float: left;
        height: 100%;
      }
    }
    & > #roulette-copy-button-container {
      display: table;
      @include breakpoint($medium-down) {
        padding: 20px;
        margin-bottom: 30px;
      }
      @include breakpoint($landscape-up) {
        padding: 20px;
        .game-rules {
          &.desktop {
            position: absolute;
            width: 100%;
            right: 0;
            bottom: 0;
          }
        }
        #roulette-game-loading,
        .game-unavailable:not(.hide) {
          display: table-cell;
          width: 100%;
          vertical-align: middle;
          height: 100%;
        }
      }
      #win-message {
        h2 {
          &.prize {
            font-size: 32px;
          }
        }
      }
      .win-msg-discount-copy {
        margin-bottom: 20px;
      }
      p {
        margin: 0 auto;
        margin-top: 20px;
      }
    }
    & > .message {
      @include breakpoint($landscape-up) {
        .game-rules {
          position: absolute;
          width: 100%;
          right: 0;
          bottom: 0;
        }
      }
      p {
        line-height: 20px;
      }
    }
    & > #roulette-wheel-container {
      background: $color-pink;
      @include breakpoint($medium-down) {
        margin-bottom: -130px;
        margin-top: 20px;
      }
      & > #roulette-pointer {
        z-index: 2;
        position: absolute;
        @include breakpoint($medium-down) {
          top: -45px;
          left: 50%;
          margin-left: -20px;
        }
        @include breakpoint($landscape-up) {
          top: -50px;
          left: 196px;
        }
        &.left-right {
          -webkit-animation: left-right 0.2s;
          -moz-animation: left-right 0.2s;
          -ms-animation: left-right 0.2s;
          -o-animation: left-right 0.2s;
          animation: left-right 0.2s;
          -webkit-animation-iteration-count: 18;
          animation-iteration-count: 18;
          -webkit-animation-timing-function: ease-out; /* Safari 4.0 - 8.0 */
          animation-timing-function: ease-out;
        }
      }
      & > .roulette-wheel {
        z-index: 1;
        position: relative;
        -ms-transform: rotate(35.6deg);
        -webkit-transform: rotate(35.6deg);
        transform: rotate(35.6deg);
        @include breakpoint($medium-down) {
          top: -30px;
          & > img {
            width: 100%;
            max-width: 500px;
          }
        }
        @include breakpoint($landscape-up) {
          width: 540px;
          height: 540px;
          margin-top: -40px;
          margin-left: -50px;
        }
        &.spin_animate {
          -webkit-transition: -webkit-transform 4s ease-out;
          -moz-transition: -moz-transform 4s ease-out;
          -o-transition: -o-transform 4s ease-out;
          transition: transform 4s ease-out;
        }
        &.spin_again {
          -webkit-transform: rotate(1116deg);
          -moz-transform: rotate(1116deg);
          transform: rotate(1116deg);
        }
        &.spin_again_2 {
          -webkit-transform: rotate(2196deg);
          -moz-transform: rotate(2196deg);
          transform: rotate(2196deg);
          &.roulette_3 {
            -webkit-transform: rotate(3099deg);
            -moz-transform: rotate(3099deg);
            transform: rotate(3099deg);
          }
          &.roulette_5 {
            -webkit-transform: rotate(3312deg);
            -moz-transform: rotate(3312deg);
            transform: rotate(3312deg);
          }
          &.roulette_4 {
            -webkit-transform: rotate(3026deg);
            -moz-transform: rotate(3026deg);
            transform: rotate(3026deg);
          }
          &.roulette_2 {
            -webkit-transform: rotate(3169deg);
            -moz-transform: rotate(3169deg);
            transform: rotate(3169deg);
          }
          &.roulette_1 {
            -webkit-transform: rotate(2519deg);
            -moz-transform: rotate(2519deg);
            transform: rotate(2519deg);
          }
        }
        &:not(.spin_again):not(.spin_again_2) {
          &.roulette_3 {
            -webkit-transform: rotate(938deg);
            -moz-transform: rotate(938deg);
            transform: rotate(938deg);
          }
          &.roulette_5 {
            -webkit-transform: rotate(1152.6deg);
            -moz-transform: rotate(1152.6deg);
            transform: rotate(1152.6deg);
          }
          &.roulette_4 {
            -webkit-transform: rotate(1225deg);
            -moz-transform: rotate(1225deg);
            transform: rotate(1225deg);
          }
          &.roulette_2 {
            -webkit-transform: rotate(1009.6deg);
            -moz-transform: rotate(1009.6deg);
            transform: rotate(1009.6deg);
          }
          &.roulette_1 {
            -webkit-transform: rotate(3099deg);
            -moz-transform: rotate(3099deg);
            transform: rotate(1078.6deg);
          }
        }
        &:not(.spin_again_2) {
          &.roulette_5 {
            -webkit-transform: rotate(2232deg);
            -moz-transform: rotate(2232deg);
            transform: rotate(2232deg);
          }
        }
        &.spin_again:not(.spin_again_2) {
          &.roulette_3 {
            -webkit-transform: rotate(2375deg);
            -moz-transform: rotate(2375deg);
            transform: rotate(2375deg);
          }
          &.roulette_4 {
            -webkit-transform: rotate(1943deg);
            -moz-transform: rotate(1943deg);
            transform: rotate(1943deg);
          }
          &.roulette_2 {
            -webkit-transform: rotate(2088deg);
            -moz-transform: rotate(2088deg);
            transform: rotate(2088deg);
          }
          &.roulette_1 {
            -webkit-transform: rotate(1440.6deg);
            -moz-transform: rotate(1440.6deg);
            transform: rotate(1440.6deg);
          }
        }
      }
    }
    @include breakpoint($small-down) {
      h2 {
        font-size: 24px;
      }
      .game-title {
        h2 {
          display: inline-block;
          margin-bottom: 0 !important;
        }
      }
      & > #roulette-copy-button-container {
        &.mobile-message {
          position: absolute;
          background: $color-white;
          z-index: 4;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          box-sizing: border-box;
          border: 8px solid $color-pink;
        }
        .message {
          width: 100%;
          height: 100%;
          vertical-align: middle;
          display: table-cell;
        }
      }
    }
    @include breakpoint($medium-up) {
      h2 {
        &.first-line-title {
          font-size: 70px;
          margin-top: 10px;
        }
        &.second-line-title {
          font-size: 60px;
        }
      }
    }
    @include breakpoint($medium-down) {
      #roulette-game-loading {
        h2 {
          margin-top: 40px;
        }
      }
      .game-rules {
        &.mobile {
          background: $color-white;
          margin-top: 60px;
          padding-top: 80px;
          min-height: 250px;
        }
      }
      .message {
        .game-rules {
          margin-top: 20px;
        }
      }
    }
    @include breakpoint($landscape-up) {
      h2 {
        font-size: 40px;
        margin-bottom: 53px;
      }
      .main-copy,
      #spin-again-message {
        p {
          height: 90px;
          padding: 10px 0 20px 0;
          font-size: 18px;
          line-height: 1.45em;
        }
      }
      .game-unavailable {
        p {
          font-size: 18px;
          line-height: 1.45em;
        }
      }
    }
  }
  @include breakpoint($medium-down) {
    .mobile {
      display: block;
    }
    .desktop {
      display: none !important;
    }
  }
  @include breakpoint($landscape-up) {
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
      margin-top: 9px;
    }
  }
  .terms {
    p {
      font-size: 11px;
      line-height: 14px !important;
      margin-top: 20px;
      @include breakpoint($medium-down) {
        width: 90%;
        max-width: none;
      }
      @include breakpoint($landscape-up) {
        width: 80% !important;
        max-width: 600px;
      }
    }
  }
  @-webkit-keyframes left-right {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(-25deg);
      -o-transform: rotate(-25deg);
      transform: rotate(-25deg);
    }
  }
  @keyframes left-right {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(-25deg);
      -moz-transform: rotate(-25deg);
      -webkit-transform: rotate(-25deg);
      -o-transform: rotate(-25deg);
      transform: rotate(-25deg);
    }
  }
}
