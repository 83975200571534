@mixin viewcart_panel_height {
  min-height: inherit;
}

@mixin viewcart_panel_display {
  display: block !important;
}

@mixin viewcart_panel_hide_title {
  display: none;
}

.viewcart {
  .sticky-checkout-button,
  .sticky-checkout-overlay {
    display: none;
  }
  header.checkout-header {
    .top-viewcart-buttons {
      .continue-buttons {
        display: none;
      }
      .continue-shopping {
        display: block !important;
        visibility: visible;
      }
    }
    .checkout-header {
      &__title {
        display: block;
        font-size: 21px;
        clear: left;
        text-transform: uppercase;
        font-family: $brandon-text-bold;
      }
    }
    .continue-shopping {
      display: none;
    }
  }
  .bottom-viewcart-buttons {
    .viewcart-buttons-panel {
      border: none;
      .continue-buttons {
        .paypal-checkout {
          background: none;
          padding-bottom: 14%;
          border: 1px solid $color-black;
        }
        .continue-checkout {
          padding-top: 6%;
          padding-bottom: 6.5%;
        }
      }
    }
  }
  &.panel {
    .checkout__sidebar & {
      padding: 13px 19px;
    }
    &__title {
      .checkout__content & {
        border-bottom: 2px solid $color-black;
      }
      .checkout__sidebar & {
        @include breakpoint($medium-up) {
          display: inline-block !important;
          padding-bottom: 0;
        }
        text-indent: 0;
        border-bottom: none;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
  .viewcart-panel {
    .checkout {
      &__panel-content {
        .cart-item {
          .cart-item {
            &__price {
              width: 18%;
              font-size: 14px;
            }
            &__qty {
              width: 15%;
            }
          }
          .color {
            .swatch {
              margin-top: -9%;
            }
          }
          .product__thumb {
            .cart-item {
              &__thumb-image {
                padding: 0px;
              }
            }
          }
          .replenishment {
            padding-top: 40px;
            float: none;
            width: 500px;
            overflow: hidden;
            .replenishment {
              &__description {
                margin-bottom: 0.3em;
                letter-spacing: 0px;
                width: 250px;
                margin-top: 1%;
                float: left;
                a {
                  margin-left: 2%;
                }
              }
            }
            .alter_replenishment_form--container {
              width: 229px;
              float: left;
              .selectbox {
                width: 100%;
              }
            }
          }
        }
        .cart-items {
          .sample {
            .desc {
              .cart-item {
                &__size {
                  display: none;
                }
              }
            }
          }
          .cart-item__remove-form {
            .cart-item__add-to-favorites {
              display: none;
            }
          }
          .hazmat {
            color: $color-red;
            padding: 2% 0 0 16%;
          }
        }
        .cart-header {
          &__title {
            &--products {
              width: 25%;
              float: left;
              clear: none;
            }
            &--price {
              width: 17%;
              clear: right;
              float: left;
              padding-left: 29%;
            }
            &--qty {
              width: 22%;
              float: left;
              padding-left: 16%;
            }
            &--total {
              width: 13%;
              float: right;
              padding-left: 0%;
            }
          }
        }
      }
    }
    .top-viewcart-buttons {
      display: none;
    }
  }
  &-header {
    .checkout__sidebar & {
      padding-bottom: 0;
      min-height: 35px;
      padding-top: 3px;
      @include breakpoint($medium-up) {
        border-bottom: 1px solid $color-black;
      }
      @include breakpoint($small-down) {
        border-bottom: none;
        .edit {
          display: none;
        }
      }
    }
  }
  // cart items header
  &-buttons {
    &-panel {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid $color-gray;
      .content {
        padding-right: 20px;
      }
      .continue-checkout-btns {
        display: inline-block;
        a {
          float: left;
        }
        .continue-checkout {
          margin-right: 5px;
        }
      }
    }
  }
  .checkout__sidebar {
    .tab-switcher {
      .tab-content {
        background-color: $color-gray-background;
        #past-purchases-pane {
          .past-purchases-panel {
            .content {
              @include viewcart_panel_display;
              .product-list {
                @include viewcart_panel_height;
              }
            }
            .past-purchases-panel {
              &__title {
                @include viewcart_panel_hide_title;
              }
            }
          }
        }
        #favorites-pane {
          .favorites-panel {
            .content {
              @include viewcart_panel_display;
              .product-list {
                @include viewcart_panel_height;
              }
            }
            .favorites-panel {
              &__title {
                @include viewcart_panel_hide_title;
              }
            }
          }
        }
        #samples-pane {
          .samples-panel {
            .content {
              .form-item {
                a {
                  display: block;
                  bottom: inherit;
                  text-align: center;
                  position: relative;
                }
              }
              .samples {
                .product-list {
                  overflow: hidden;
                }
              }
            }
            .samples-panel {
              &__title {
                @include viewcart_panel_hide_title;
              }
            }
          }
        }
      }
    }
    .need-help-panel {
      background-color: $color-gray-background;
      padding: 5%;
    }
  }
  // BOGO OfferCode Styles
  .bogo-offer {
    .cart-item {
      &__replenishment,
      &__remove-form,
      &__add-to-favorites {
        display: none;
      }
    }
  }
}
