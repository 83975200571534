.bobbi-pro {
  .bobbi-pro {
    &__section--benefits,
    &__apply {
      p {
        line-height: 24px;
      }
    }
    &__apply {
      ul {
        li {
          font-family: $brandon-text;
        }
      }
    }
    &__hero {
      @include breakpoint(max-width $tablet) {
        background-size: 85%;
      }
    }
  }
  @include breakpoint(max-width $tablet) {
    .resp-tabs-container > * {
      &[aria-controls='tab_item-3'] {
        display: block;
      }
    }
  }
}

.pro_show {
  display: block;
}

.pro_hide {
  display: none;
}
