.section-customer-service-contact-us {
  .customer-service-contact-form__section {
    #category-online-content {
      .customer-service-contact-form__radio-item {
        label {
          .orderstatus_exchanges_radiobutton {
            vertical-align: middle;
            margin-top: 1px;
            display: inline-block;
          }
        }
        label:before {
          font-size: 13px;
        }
      }
    }
  }
}

.site-footer-contact {
  .site-footer-contact__item.customerservice_width {
    width: 100%;
  }
  @media (min-width: 768px) {
    .site-footer-contact__item.customerservice_width {
      width: 50%;
      a {
        margin-left: 25%;
      }
    }
  }
}

.responsive-tabs {
  .resp-tabs-list {
    .bv_ask_tab {
      display: none;
    }
  }
}

.quickshop__container {
  .product--full {
    .product__rating {
      div.t-desktop {
        display: none;
      }
      span {
        div.t-desktop {
          display: inline;
        }
      }
    }
  }
}

.product__details {
  .product__rating {
    #BVRRSummaryContainer {
      width: 100%;
      margin-top: 7px;
      margin-bottom: 20px;
    }
    #BVQASummaryContainer {
      display: none;
    }
  }
}

.password-sent-page {
  .password-sent-page__content {
    .sent-info {
      clear: left;
    }
  }
}

.site-utils__right {
  .site-utils__menu {
    .site-utils__item {
      vertical-align: top;
    }
  }
}

.section-store-locator {
  .store-locator__links-container {
    .store-locator__link-events {
      float: right;
      margin: 5px 0 0 20px;
    }
  }
}

.customer-service {
  .customer-service-quick-info {
    .customer-service-quick-info__list-item {
      margin-left: 14%;
    }
  }
}

.section-esearch.device-pc {
  #product-results {
    .product-grid__item {
      h3.product-thumb__headline {
        height: 42px;
      }
    }
    .product-thumb__image {
      height: auto;
    }
  }
  #bestseller-results {
    .product-grid__item {
      h3.product_bestseller_result_header {
        height: 42px;
      }
    }
  }
}

.checkout {
  .checkout__sidebar {
    section {
      margin-bottom: 10px;
    }
    #offer-code-panel {
      height: auto !important;
      h2 {
        margin: 0px;
      }
      #offer_code {
        p {
          margin-bottom: 15px;
        }
        span {
          margin-bottom: 10px;
          display: block;
        }
        p#one-offer-only {
          margin-bottom: 15px;
        }
      }
    }
    #links-panel {
      background: #eff0f0;
      padding: 16px 20px;
      .content {
        padding: 10px;
      }
    }
  }
  .checkout__content {
    #shipping-page-info-panel #checkout_shipping {
      section#shipping-address-info-panel,
      #billing-address-info-panel,
      #delivery-info-panel,
      #giftwrap-info-panel,
      #emailpromo-info-panel {
        padding: 0px 20px 10px 20px;
        overflow: hidden;
      }
      .checkout-header {
        margin-bottom: 0px;
      }
      #shipping-address-info-panel {
        #shipping-info-panel {
          .address-container {
            float: none;
            margin: 0px 0px 10px 0px;
            width: auto;
          }
          ul.address_controls {
            float: none;
            li {
              margin-bottom: 10px;
              &#choose-address {
                width: auto;
                select {
                  margin-top: 10px;
                  width: 65%;
                }
              }
            }
          }
        }
      }
      #billing-address-info-panel #billing-info-panel {
        .address-container {
          margin-left: 0px;
        }
      }
      #delivery-info-panel #delivery-info-content-panel {
        a.selectBox {
          width: 65%;
        }
        .field-note {
          margin-top: 10px;
        }
      }
      #giftwrap-info-panel #gitft-info-panel {
        .gift_options_container {
          margin-top: 0px;
          .gift_form_container {
            margin-left: 0px;
            padding: 0px 20px;
            h4 {
              padding: 0px;
            }
            .gift-options-wrapper {
              margin-top: 15px;
              width: auto;
              p {
                margin: 0px;
              }
              .form-item {
                #gift-max-char {
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
      #emailpromo-info-panel {
        .newsletter-info {
          h3 {
            text-indent: 0px;
          }
          p {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

.product-palette__details-container {
  display: block;
}

.spp {
  .product {
    &__details {
      div#product__price-afterpay-installment-price {
        display: block;
        clear: both;
        float: left;
        margin-bottom: 10px;
        .afterpay_logo {
          width: 100px;
          padding-bottom: 5px;
          background: url('/media/export/cms/afterpay/ap-logo.png') no-repeat;
          height: 25px;
          display: inline-block;
          background-size: 100px;
          vertical-align: middle;
        }
        a {
          text-transform: uppercase;
          color: #000;
        }
      }
      .product-replenishment-select {
        .purchase-one-time {
          padding: 2% 0;
        }
        .purchase-replenishment {
          .og-deliver-text {
            padding-top: 2%;
          }
        }
      }
    }
  }
}

.site-footer {
  .afterpay-content,
  .afterpay-content-mb {
    display: none;
  }
  &__wrap {
    .site-footer-bottom:nth-child(2) {
      .site-footer-bottom__trademark {
        width: auto;
      }
    }
  }
}
/*  Power reviews  */

.write_a_review__container {
  #pr-write {
    #pr-war-form {
      .pr-describeyourself-form-group {
        display: none;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  #pr-reviewdisplay {
    .p-w-r {
      .pr-review-display {
        .pr-rd-sort-group {
          float: right;
        }
        .pr-review {
          .pr-rd-side-content-block.pr-rd-right {
            position: relative;
            top: 0;
            right: 0;
            width: 100%;
          }
        }
      }
    }
  }
}
/* BIS */

p.waitlist_message {
  &.waitlist_thankyou_message {
    font-family: $brandon-text;
    margin: 5px 60px;
  }
}

.bis-signup {
  .email_input {
    display: inline;
    float: left;
    input[type='email'] {
      width: 300px;
      margin-bottom: 10px;
    }
  }
}

.tiny-waitlist-overlay {
  .field {
    .align-r input {
      height: 33px;
      margin-left: 10px;
    }
  }
}

.responsive-tabs--how_to_use-wrap {
  .how-to-use__content {
    .disclaimer {
      display: block;
    }
  }
}

.site-header {
  z-index: 901;
  &.site-utils__item--dropdown-active {
    z-index: 9998;
  }
}

.p-w-r {
  .pr-star-v4,
  .pr-header-table,
  .pr-rating-stars,
  .pr-war,
  .pr-table-cell,
  .pr-header-title,
  span {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.cart_overlay {
  .cart-confirm-wrapper {
    .promos {
      .free_ship {
        height: auto;
      }
    }
  }
}

.site-nav {
  .menu__list--lvl-1 {
    .block-template-esearch-nav-v1 {
      @include breakpoint($medium-up) {
        display: block;
        padding: 0;
      }
    }
  }
}

.afterpay-content {
  .afterpay_pc_first {
    width: 597px;
    height: 192px;
  }
  .afterpay_pc_second {
    width: 597px;
    height: 202px;
  }
  .afterpay_pc_third {
    width: 597px;
    height: 112px;
  }
}

.spp-olapic {
  .olapic {
    .olapic-slider-body {
      .olapic-slider-wrapper {
        float: none;
        max-width: 790px;
      }
    }
  }
}

.spp-product-layout {
  .product-full {
    &__data {
      .product-full__ctas {
        .product-full__ctas-non-shaded-wrapper {
          display: flex;
          &.buy-now-disabled {
            .product-full__bag-btn {
              margin-#{$rdirection}: 10px;
              width: 70%;
            }
          }
        }
      }
    }
  }
  .product-full-price {
    &__afterpay-installment-price {
      .afterpay_logo {
        vertical-align: middle;
        min-width: 100px;
        min-height: 25px;
        position: absolute;
        margin-left: 5px;
      }
      #ap-learnmore {
        margin-left: 115px;
      }
    }
  }
}

.store-locator--desktop {
  #store-search-controls {
    .search-submit {
      margin-top: 0px !important;
    }
    .country {
      display: none !important;
    }
    .distance_container {
      display: inline;
    }
  }
}
