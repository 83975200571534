div.csr_phone_number {
  display: none;
}

.brand-id-BobbiBrown {
  padding: 1em;
  h3 {
    font-size: 3em;
    font-weight: normal;
    margin-bottom: 0;
    text-decoration: underline;
  }
  h4 {
    margin-top: 20px;
    font-size: 2.25em;
  }
}

#csr_header {
  top: 30px;
  .csr_table {
    table-layout: auto;
    margin: 0;
    td {
      vertical-align: middle;
      padding: 0 20px;
      border: none;
    }
    #csr_dragger {
      cursor: move;
      background: $color-red;
      border: none;
      width: 40px;
    }
  }
  a {
    display: block;
  }
  #csr_info_box {
    border-right: 1px solid $color-red;
  }
  #csr_registered_user_info,
  #cust_cart {
    border-right: 1px solid $color-red;
    line-height: 14px;
  }
}
