.click-and-collect-method-select {
  float: left;
  margin-bottom: 20px;
  margin-top: 15px;
  .radio-button {
    float: left;
    width: 66%;
  }
  .auspost-logo {
    float: right;
    width: 33%;
  }
  label {
    text-transform: none;
    span {
      display: inline-block;
    }
    &::before {
      float: left;
      margin-top: 3px;
    }
  }
  .auspost-label {
    font-weight: bold;
    max-width: 400px;
    float: left;
  }
  .auspost-note {
    display: inline-block;
    margin-left: 22px;
    margin-top: 10px;
  }
  .auspost-overlay.button {
    margin: 15px;
  }
  .auspost-address-display {
    p {
      margin: 0;
    }
  }
  #aupost-root {
    margin-top: 10px;
    input {
      height: 40px;
    }
  }
  @include breakpoint($small-down) {
    margin-top: 20px;
    .auspost-logo {
      display: none;
    }
    .radio-button {
      width: 100%;
    }
    .auspost-label {
      max-width: 90%;
    }
    .auspost-overlay.button {
      margin: 20px;
    }
    .auspost-address-display {
      margin-bottom: 15px;
    }
  }
}

#colorbox.auspost-overlay {
  .auspost-continue {
    width: 100%;
    .location-selected {
      margin: 20px auto;
      display: block;
      width: 150px;
    }
  }
  #cboxClose {
    top: 4px;
    right: 4px;
    width: 22px;
    height: 22px;
    &:before {
      font-size: 22px;
    }
  }
}
