@mixin mobile-checkout-title {
  font-family: $brandon-text-bold;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-indent: 20px;
  margin-top: 6%;
  padding-bottom: 0;
  line-height: 50px;
  background: $color-gray-background;
  border-bottom: 1px solid $color-gray-border;
  border-top: 1px solid $color-gray-border;
}

.confirm {
  #confirmation-page {
    .right.checkout {
      &__sidebar {
        @include breakpoint($medium-up) {
          float: right;
          margin-top: 3.7%;
        }
        .links-panel {
          display: none;
        }
      }
    }
    .checkout-header {
      @include breakpoint($medium-up) {
        width: 62%;
      }
      &__title {
        @include mobile-checkout-title;
        text-align: left;
      }
    }
    .confirmation-panel {
      @include breakpoint($small-down) {
        padding-bottom: 50px;
      }
      &__actions {
        display: block;
        @include breakpoint($medium-up) {
          width: 50%;
          .print-buttons {
            a.receipt-print-btn {
              background-color: $color-black;
              color: $color-white;
              display: inline-block;
              font-family: $brandon-text-bold;
              font-size: 14px;
              font-weight: normal;
              text-transform: uppercase;
              line-height: 1;
              padding: 0.75em 1em;
              text-decoration: none;
              letter-spacing: 2px;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
      &__content {
        .confirmation-panel {
          &__confirm-text {
            padding: 0px;
            a,
            #confirm_trans_order_id {
              color: $color-pink;
              text-decoration: underline;
            }
          }
        }
      }
      section.social-login {
        @include breakpoint($medium-up) {
          width: 62%;
          padding-top: 4%;
        }
        @include breakpoint($small-down) {
          padding-left: 0;
        }
      }
    }
    section.responsive-container {
      .right.checkout {
        &__sidebar {
          padding-bottom: 10%;
          #links-panel {
            margin-bottom: 5%;
            li {
              border: none;
            }
          }
          #need-help-panel {
            .checkout {
              &__panel-title {
                @include mobile-checkout-title;
              }
            }
            .content {
              .link {
                border: none;
                text-transform: none;
                font-family: $brandon-text;
                pointer-events: none;
              }
            }
          }
        }
      }
      .left {
        .registration-panel {
          &__title {
            @include breakpoint($small-down) {
              @include mobile-checkout-title;
              padding: 0px;
              text-indent: 0px;
            }
          }
          section {
            &.how-we-will-use-container,
            &.registration-form-container {
              @include breakpoint($medium-up) {
                width: 100%;
                margin: 0px;
              }
            }
          }
        }
      }
    }
    .sign-in-confirmation-panel {
      .checkout {
        &__panel-title {
          @include breakpoint($small-down) {
            @include mobile-checkout-title;
            line-height: 30px;
            text-indent: 0;
            padding-bottom: 16px;
          }
        }
      }
    }
    .checkout {
      &__panel-title {
        @include mobile-checkout-title;
      }
    }
    @include breakpoint($medium-up) {
      .form-item {
        &.password-hint {
          display: none;
        }
        &.password-description {
          margin: 5px 0 15px 0;
          display: inline-block;
        }
      }
    }
  }
  .adpl {
    .checkout {
      &__content {
        input[type='password'] + label,
        select + label {
          height: 3em;
          line-height: 3em;
          margin-top: calc(-3em - 1px);
          cursor: text;
          display: block !important;
          visibility: visible;
        }
      }
    }
    label {
      display: block !important;
      visibility: visible;
      border: none;
      margin: 0px;
    }
  }
}
