/* Sample Overlay */
.samples-overlay {
  #cboxContent {
    #samples-panel {
      div {
        &.offer {
          ul.product-list {
            li.product {
              float: left;
              margin: 30px 15px;
              width: 170px;
              min-height: 200px;
              div.product-img {
                text-align: center;
              }
              div.details {
                h4.product-name {
                  font-size: 11px;
                  line-height: 1.2em;
                  height: 54px;
                }
                .product-name {
                  min-height: 57px;
                }
                a.sample-select-button {
                  display: block;
                  width: auto;
                  position: relative;
                }
              }
            }
          }
        }
        &.add-button {
          margin: 0 0 50px;
          text-align: center;
          input.btn {
            background: $color-pink;
            padding: 5px 20px;
            height: auto;
          }
        }
      }
      .samples-buttons {
        clear: both;
      }
    }
  }
}
